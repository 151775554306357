<template>
  <div class="panel-group-component">
    <div
      v-for="(panel, i) in panels"
      :key="i"
      class="fusion-panel panel-default"
    >
      <div
        class="panel-heading"
        :class="{ selected: selected === i }"
        @click="selected = selected === i ? null : i"
      >
        <h4 class="panel-title toggle fusion-responsive-typography-calculated">
          <div style="margin-right:-8px;" class="fa-fusion-box">
            <span v-if="selected !== i">+</span>
            <span v-if="selected === i">-</span>
          </div>
          <div class="fusion-toggle-heading" style="display: inline;">
            {{ panel.title }}
          </div>
        </h4>
      </div>
      <div :class="{ collapse: selected !== i }" class="panel-collapse">
        <div
          class="panel-body toggle-content fusion-clearfix"
          :class="{ 'mt-4': panel.contentLink && panel.contentLink.button }"
        >
          <span v-html="panel.content1"></span>
          <router-link
            v-if="panel.contentLink"
            :to="panel.contentLink.to"
            :class="{
              'bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 button':
                panel.contentLink.button
            }"
          >
            {{ panel.contentLink.text }}
          </router-link>
          <span v-html="panel.content2"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PanelGroupComponent",
  props: {
    panels: Array
  },
  data: () => {
    return {
      selected: null
    };
  },
  mounted() {
    this.$el.onclick = e => {
      if (e.target.nodeName === "BUTTON") {
        if (this.$router.currentRoute.path !== e.target.dataset.link) {
          this.$router.push({ path: e.target.dataset.link });
        }
      }
    };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.panel-group-component {
  text-align: left;

  .collapse {
    display: none;
  }

  .panel-heading {
    padding: 10px 0;
    border-bottom: 1px solid #e5e4e3;
    cursor: pointer;
  }

  .panel-heading:hover,
  .panel-heading.selected {
    .fa-fusion-box {
      background-color: #aa222c;
    }
  }

  .panel-body {
    font-size: 13px;
    padding: 10px 0 15px;
    color: #747474;
    a:not(.button) {
      color: #aa222c;
    }
    ul {
      list-style: disc;
      padding-left: 30px;
      margin-top: 10px;
    }
  }

  .fusion-toggle-heading {
    color: #aa222c;
    margin-left: 18px;
  }

  .fa-fusion-box {
    text-align: center;
    color: white;
    font-weight: bold;
    width: 22px;
    height: 22px;
    background-color: #333333;
    display: inline-block;
  }
}
</style>
